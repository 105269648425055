<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="11">
                <b-card>
                    <b-row class="align-items-center">
                        <b-col sm="12" md="8" lg="8">
                            <b-row class="align-items-center">
                                <b-col sm="12" md="6">
                                    <b-form-group class="mr-md-2" label="Pilih Item LPJ">
                                        <v-select :options="items" v-model="form.item" label="text" :reduce="option => option.value"></v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" md="6">
                                    <b-form-group class="m" label="Pilih Tahun">
                                        <v-select :options="years" v-model="form.tahun" ></v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                        </b-col>
                        <b-col sm="12" md="4" >
                            <div class="d-flex justify-content-start">
                                <b-button variant="primary" :disabled="!isValidForm" @click.prevent="exportLpj">Export</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BButton, BFormGroup} from 'bootstrap-vue'
import vSelect from 'vue-select'
import service from '@/services'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BButton, BFormGroup, vSelect
    },
    data: () => ({
        loading: false,
        items: [],
        form: {
            item: null,
            tahun: null
        },
        years: []
    }),
    computed: {
        isValidForm() {
            if(!this.form.item || !this.form.tahun) {
                return false
            }

            return true
        }
    },
    methods: {
        async exportLpj() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap pilih item lpj beserta tahun nya!'
                })
                return false
            }

            
            try {
                this.loading = true
                const config = {
                    method: 'get',
                    url: `/excel-rekap-biaya-bop-sales`,
                    params: this.form,
                    headers: {
                        Authorization: `bearer ${localStorage.token}`
                    }
                }

                const response = await service.sendRequest(config)
                const result = response.data

                await window.open(result)
                setTimeout(async () => {
                    // delete selected file
                    const arrFile = result.split('/')
                    const filename = arrFile[ arrFile.length - 1 ]

                    await this.clearExportedFile(filename)
                }, 1000)
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async getLpjItems() {
            this.loading = true
            const items = await this.$store.dispatch('bop/getLpjItem')
            this.items = items.map(item => ({value: item, text: item}))
            this.loading = false
        },
        generateYears(startYear) {
            const currentYear = new Date().getFullYear(),
                years = []

            startYear = startYear || 1970

            while( startYear <= currentYear ) {
                years.push(startYear++)
            }

            return years
        }
    },
    created() {
        this.years = this.generateYears(2000)
        this.getLpjItems()
    }
}
</script>